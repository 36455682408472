<template>
  <div class="event" v-if="event">
    <page-title class="hidden-xs-only" :page="pageTitleData" />
    <Breadcrumb :items="breadCrumbs" />
    <v-container fluid class="relative">
      <v-row style="background-color:#F8F8F8; !important">
        <v-col cols="12">
          <v-container>
            <v-card class="w-100 mx-auto my-12" elevation="6">
              <v-row no-gutters dense>
                <v-col cols="12" md="6" class="pr-2">
                  <div class="event-date" v-if="isFutureEvent">
                    <span class="day">
                      {{ event.data_evento.split("-")[2] }}</span
                    >
                    <span class="month">{{ formatMonth }}</span>
                  </div>
                  <v-img
                    v-if="
                      event.yoast_head_json && event.yoast_head_json.og_image
                    "
                    eager
                    class="event-thumb"
                    :src="
                      event.yoast_head_json &&
                        event.yoast_head_json.og_image[0].url
                    "
                  ></v-img>

                  <v-card-text class="mt-2">
                    <div class="event-info d-flex">
                      <div class="event-info-icon pr-1">
                        <v-icon size="20" color="primary">
                          mdi-flag-outline
                        </v-icon>
                      </div>
                      <div class="event-info-text">
                        <strong>{{ $t("events.partnership") }}</strong>
                        <div>
                          {{ event.organizzatore }}
                        </div>
                      </div>
                    </div>

                    <div class="event-info d-flex" v-if="isFutureEvent">
                      <div class="event-info-icon pr-1">
                        <v-icon size="20" color="primary">
                          mdi-map-marker-outline
                        </v-icon>
                      </div>
                      <div class="event-info-text">
                        <strong>{{ $t("events.where") }}</strong>
                        <div>
                          {{ event.luogo }}
                        </div>
                      </div>
                    </div>

                    <div class="event-info d-flex" v-if="isFutureEvent">
                      <div class="event-info-icon pr-1">
                        <v-icon size="20" color="primary"
                          >mdi-calendar-outline</v-icon
                        >
                      </div>
                      <div class="event-info-text">
                        <strong>{{ $t("events.when") }}</strong>
                        <div>{{ formatDate }}</div>
                      </div>
                    </div>
                  </v-card-text>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card-title class="event-title text-h4 mt-2">{{
                    event.title.rendered
                  }}</v-card-title>
                  <v-card-text
                    class="event-description"
                    v-html="event.content.rendered"
                  ></v-card-text>
                  <v-card-subtitle
                    class="text-h6 font-weight-bold secondary--text"
                    >{{ $t("events.forms.subtitle") }}</v-card-subtitle
                  >
                  <NinjaForm
                    v-if="event"
                    :formId="ninjaFormId"
                    :options="FormOptions"
                    :xdata="event"
                    :key="key"
                  ></NinjaForm>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-container
            ><h2 class="secondary--text">
              {{ $t("events.futureEvents.titleAlt") }}
            </h2>
            <EventListSlider from="future" :eventToExclude="event" :key="key">
            </EventListSlider
          ></v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style scoped lang="scss">
:deep(.ninja-form .v-input--radio-group) {
  font-weight: normal !important;
}
.event-title {
  font-weight: bold;
  color: var(--v-accent-darken4) !important;
  word-break: break-word;
}
.event-description {
  color: var(--v-accent-darken4);
  font-size: 1.1rem;
}
.event-info {
  font-size: 1rem;
  .event-info-text strong {
    font-size: 1.2rem;
  }
  margin-bottom: 20px;
  color: #7b7b7b;
}
.event-thumb {
  border-radius: 8px 0px 0px 0px;
}
.event-date {
  width: 48px;
  height: 48px;
  padding: 8px;
  background-color: #ffffff;
  color: #7b7b7b;
  border-radius: 8px !important;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1rem;
  z-index: 2;
  position: absolute;
  top: 8px;
  left: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  .day,
  .month {
    display: block;
    text-align: center;
    text-transform: capitalize;
  }
}
</style>
<script>
import CMSService from "~/service/cmService";
import page from "~/mixins/page";
import clickHandler from "~/mixins/clickHandler";
import NinjaForm from "@/commons/components/ninjaForms/NinjaForm.vue";
import EventListSlider from "@/components/wordpress/EventListSlider.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import PageTitle from "@/components/wordpress/PageTitle.vue";
import get from "lodash/get";

export default {
  name: "Event",
  data() {
    return {
      key: 1,
      event: null,
      isFutureEvent: false,
      pageTitleData: {
        title: {
          rendered: "Area didattica – GrosMarket LiveLab"
        },
        featured_image_url:
          "/p/wp-content/uploads/2021/06/area-didattica-header.png"
      },
      FormOptions: {
        dense: true,
        outlined: true,
        flat: true,
        depressed: true
      }
    };
  },
  components: { NinjaForm, EventListSlider, Breadcrumb, PageTitle },
  mixins: [page, clickHandler],
  comments: { NinjaForm },
  computed: {
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const arrFiltered = pathArray.filter(el => {
        return el != null && el != "";
      });
      const breadCrumbs = [{ href: "/", text: "Homepage" }];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      // let lastIndexFound = 0;
      for (let i = 0; i < arrFiltered.length; ++i) {
        breadcrumb = `${breadcrumb}${"/"}${arrFiltered[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            href: breadcrumb,
            // i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            disabled: i + 1 === arrFiltered.length,
            text: this.$route.matched[i].meta.breadCrumb || arrFiltered[i]
          });
          // lastIndexFound = i;
          // breadcrumb = "";
        }
      }
      return breadCrumbs;
    },
    formatDate() {
      const date = this.$dayjs(this.event.data_evento);
      const hourStart = this.$dayjs(
        `${this.event.data_evento} ${this.event.orario_inizio}`
      );
      const hourEnd = this.$dayjs(
        `${this.event.data_evento} ${this.event.orario_fine}`
      );
      return `${date.format("DD MMMM YYYY")} | ${hourStart.format(
        "HH:mm"
      )} - ${hourEnd.format("HH:mm")}`;
    },
    formatMonth() {
      return this.$dayjs(this.event.data_evento).format("MMM");
    },

    ninjaFormId() {
      return this.isFutureEvent ? 6 : 7;
    }
  },
  methods: {
    async fetchEvent(evento) {
      let res = await CMSService.getCustomPostBySlug("evento", evento);
      if (res.length) {
        this.event = res[0];
        //this.page = res[0];
        const currentDate = new Date();
        const eventDate = new Date(
          this.event.data_evento + "T" + this.event.orario_inizio
        );
        if (eventDate >= currentDate) {
          this.isFutureEvent = true;
        } else {
          this.isFutureEvent = false;
        }

        this.key++;
      }
    }
  },
  async mounted() {
    if (this.$route.params.additionalData) {
      this.additionalData = this.$route.params.additionalData;
    }
    // this.fetchPage(this.$route.params.evento);

    await this.fetchEvent(this.$route.params.event);
  },
  watch: {
    "$route.params.event": async function(event) {
      await this.fetchEvent(event);
    }
  },
  jsonld() {
    if (this.event) {
      return {
        "@context": "https://schema.org",
        "@type": "Event",
        name: this.event.name,
        startDate: this.event.data_evento,
        location: {
          "@type": "Place",
          name: this.event.luogo
          // address: {
          //   addressLocality: "Denver",
          //   addressRegion: "CO",
          //   postalCode: "80209",
          //   streetAddress: "7 S. Broadway"
          // }
        },
        offers: {
          "@type": "Offer",
          price: "0.0",
          priceCurrency: "EUR"
        },
        datePublished: this.event.date,
        dateModified: this.event.date,
        publisher: {
          "@type": "Organization",
          name: global.config?.defaultPageName,
          logo: {
            "@type": "ImageObject",
            url: "/logo/social-logo.png"
          }
        }
      };
    }
  },
  metaInfo() {
    return {
      title: get(this.event, "meta.seo_title"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(this.event, "meta.seo_description")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "it_IT"
        },
        {
          property: "og:title",
          content: get(
            this.event,
            "meta.seo_title",
            global.config?.defaultPageName
          )
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          property: "og:image:alt",
          content: get(this.events, "meta.seo_description")
        },
        {
          property: "og:site_name",
          content: global.config?.defaultPageName
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: get(this.page, "meta.seo_title", "Pagina statica")
        },
        {
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
