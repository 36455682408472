<template>
  <div class="events" v-if="events">
    <v-container class="relative">
      <v-row>
        <v-col>
          <div v-if="events.length > 0">
            <swiper :options="swiperOption" ref="swiperRef" class="py-4">
              <swiper-slide v-for="event in events" :key="event.id">
                <v-card
                  class="event-card mx-auto"
                  elevation="6"
                  max-width="350"
                >
                  <div
                    v-if="from === 'future'"
                    class="event-date d-flex flex-column justify-center"
                  >
                    <span class="day">
                      {{ event.data_evento.split("-")[2] }}</span
                    >
                    <span class="month">{{ formatMonth(event) }}</span>
                  </div>

                  <router-link :to="`/area-didattica-livelab/${event.slug}`">
                    <v-img
                      v-if="
                        event.yoast_head_json && event.yoast_head_json.og_image
                      "
                      class="event-thumb rounded-b-0"
                      height="250"
                      :src="
                        event.yoast_head_json &&
                          event.yoast_head_json.og_image[0].url
                      "
                    ></v-img>
                  </router-link>

                  <v-card-title class="event-title align-start">{{
                    event.title.rendered
                  }}</v-card-title>

                  <v-card-text
                    class="align-start"
                    :class="
                      from !== 'future' ? 'event-infos-past' : 'event-infos'
                    "
                  >
                    <div class="event-info d-flex">
                      <div class="event-info-icon pr-1">
                        <v-icon size="20" color="primary"
                          >mdi-flag-outline</v-icon
                        >
                      </div>
                      <div class="event-info-text">
                        <strong>{{ $t("events.partnership") }}</strong>
                        <div>
                          {{ event.organizzatore }}
                        </div>
                      </div>
                    </div>

                    <div v-if="from === 'future'" class="event-info d-flex">
                      <div class="event-info-icon pr-1">
                        <v-icon size="20" color="primary"
                          >mdi-map-marker-outline</v-icon
                        >
                      </div>
                      <div class="event-info-text">
                        <strong>{{ $t("events.where") }}</strong>
                        <div>
                          {{ event.luogo }}
                        </div>
                      </div>
                    </div>

                    <div v-if="from === 'future'" class="event-info d-flex">
                      <div class="event-info-icon pr-1">
                        <v-icon size="20" color="primary"
                          >mdi-calendar-outline</v-icon
                        >
                      </div>
                      <div class="event-info-text">
                        <strong>{{ $t("events.where") }}</strong>
                        <div>{{ formatDate(event) }}</div>
                      </div>
                    </div>
                  </v-card-text>

                  <v-divider class="mx-4"></v-divider>
                  <v-card-actions class="justify-center">
                    <v-btn
                      v-if="from === 'future'"
                      color="primary"
                      dense
                      depressed
                      class="px-5 my-1"
                      :to="`/area-didattica-livelab/${event.slug}`"
                    >
                      {{ $t("events.futureEvents.cta") }}
                    </v-btn>
                    <v-btn
                      v-else
                      color="primary"
                      dense
                      depressed
                      outlined
                      class="px-5 my-1"
                      :to="`/area-didattica-livelab/${event.slug}`"
                    >
                      {{ $t("events.pastEvents.cta") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </swiper-slide>
            </swiper>
            <div
              class="swiper-pagination w-100"
              :class="`event-${from}`"
              slot="pagination"
            ></div>
          </div>
          <div v-else>
            <h3 class="mt-12 ml-n1">
              Non ci sono eventi {{ from === "past" ? "passati" : "futuri" }} da
              mostrare
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.event-card {
  border-radius: 8px;
}
.event-title {
  font-weight: bold;
  color: #7b7b7b;
  line-height: 1.5rem;
  font-size: 1.2rem;
  word-break: break-word;
}
.event-thumb {
  border-radius: 8px 8px 0px 0px;
}
.event-date {
  width: 48px;
  height: 48px;
  padding: 8px;
  background-color: #ffffff;
  color: #7b7b7b;
  border-radius: 8px !important;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.3rem;
  z-index: 2;
  position: absolute;
  top: 8px;
  left: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  .day,
  .month {
    display: block;
    text-align: center;
    text-transform: capitalize;
  }
}
.event-title {
  height: 120px;
}
.event-infos {
  width: initial !important;
  height: 150px;
}
.event-infos-past {
  width: initial !important;
  height: 64px;
}
.swiper-pagination {
  width: 100%;
  max-width: 100%;
  position: relative !important;
}
</style>
<script>
import CMSService from "~/service/cmService";
import page from "~/mixins/page";
export default {
  name: "EventsList",
  data() {
    return {
      events: null,
      slidesPerView: 3,
      swiperOption: {
        slidesPerView: this.slidesPerView,
        spaceBetween: 32,
        autoplay: false,
        loop: false,
        watchOverflow: true,
        pagination: {
          el: `.event-${this.from}`,
          clickable: true
        },
        breakpoints: {
          320: {
            infinite: true,
            slidesPerView: 1,
            centeredSlides: false
          },
          480: {
            slidesPerView: 1,
            centeredSlides: false
          },
          640: {
            slidesPerView: 2,
            centeredSlides: false
          },
          960: {
            slidesPerView: 3,
            centeredSlides: false
          },
          1300: {
            slidesPerView: 4,
            centeredSlides: false
          }
        }
      }
    };
  },
  props: {
    from: { type: String, required: false },
    eventToExclude: { type: Object, required: false }
  },
  mixins: [page],
  mounted() {
    this.fetchEvents();
  },
  methods: {
    async fetchEvents() {
      let pastEvents = null;
      let futureEvents = null;
      let params = [{ filterName: "data_evento", filterValue: true }];
      let res = await CMSService.getCustomPostByFilters(
        "evento",
        params,
        12,
        this.pageNum
      );
      if (res.data && res.data.length) {
        futureEvents = res.data;
      }
      params = [{ filterName: "data_evento", filterValue: false }];
      res = await CMSService.getCustomPostByFilters(
        "evento",
        params,
        12,
        this.pageNum
      );
      if (res.data && res.data.length) {
        pastEvents = res.data;
      }
      if (this.from === "past") {
        this.events = pastEvents;
      } else if (this.from === "future") {
        this.events = futureEvents;
      } else {
        this.events = [...pastEvents, ...futureEvents];
      }
      if (this.eventToExclude) {
        this.events = this.events.filter(
          event => event.id !== this.eventToExclude.id
        );
      }
    },
    formatMonth(event) {
      return this.$dayjs(event.data_evento).format("MMM");
    },
    formatDate(event) {
      const date = this.$dayjs(event.data_evento);
      const hourStart = this.$dayjs(
        `${event.data_evento} ${event.orario_inizio}`
      );
      const hourEnd = this.$dayjs(`${event.data_evento} ${event.orario_fine}`);
      return `${date.format("DD MMMM YYYY")} | ${hourStart.format(
        "HH:mm"
      )} - ${hourEnd.format("HH:mm")}`;
    }
  }
};
</script>
